<template>
  <div>
     <el-card v-show="!$route.meta.showDetail">
      <search-form ref="searchForm" :topVisible="true" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
         <template #topBtn>
          <el-button class="marginRight20" type="primary" size="small" @click="handleClick('create')">{{ $t('text.add') }}</el-button>
        </template>
      </search-form>
      <create-table :operationColWidth="150" @handleNewTab="handleNewTab($event, 'bannerDetail')" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort" @onSwitchChange="handleSwitchChange" @forwardToPage="forwardToPage">
        <template #topButton>
          <span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
          <allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
        </template>
        <template #rightBtn="scope">
          <el-button class="margintop10" type="primary" size="mini" @click="handleCalendar(scope.row)">{{$t('form.hotel.priceCalendar')}}</el-button>
          <el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </create-table>
    </el-card>
    <router-view/>
    <dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
  </div>
</template>

<script>
import { getPolicySetup, getPolicyList, deletePolicyDetails, updatePolicyOnline, updatePolicyOffline } from '@/services/hotel'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
import loading from '@/utils/loading.json'
import Utils from '@/utils/Utils'
export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  watch: {
    $route (to, from) {
      // console.log(from)
      // if (from.name === 'managerUserOrderView') {
      //   this.$nextTick(() => {
      //     this.search()
      //     this.$refs.createTable.$refs.table.doLayout()
      //   })
      // }
    }
  },
  created () {
    this.queryData(getPolicySetup)
  },
  activated () {
    if (this.$route.params.hotel_room_id_eq && this.searchFormData.length > 0) {
      this.$refs.searchForm.createData.hotel_room_id_eq = Number(this.$route.params.hotel_room_id_eq)
      this.searchFormData[1].disabled = true
    }
    if (this.isActive) this.queryData(getPolicySetup)
    this.isActive = true
  },
  methods: {
    queryOpearte () {
      if (!Utils.isNull(this.$route.params.hotel_room_id_eq)) {
        this.$refs.searchForm.createData.hotel_room_id_eq = Number(this.$route.params.hotel_room_id_eq)
        this.searchFormData[1].disabled = true
      }
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'policyAdd',
          params: {
            hotel_room_id_eq: this.$route.params.hotel_room_id_eq
          }
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'policyDetail',
          params: {
            id: data.id.value,
            hotel_room_id_eq: this.$route.params.hotel_room_id_eq
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'policyAdd',
          params: {
            hotel_room_id_eq: this.$route.params.hotel_room_id_eq
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'policyEdit',
          params: {
            id: data.id.value,
            hotel_room_id_eq: this.$route.params.hotel_room_id_eq
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deletePolicyDetails)
      }
    },
    handleCalendar (data) {
      this.$router.push({
        name: 'policyCalendarEdit',
        params: {
          id: data.id.value
        }
      })
    },
    forwardToPage (data, name) {
      if (data.model_name && data.model_name === 'hotel') {
        this.$router.push({
          name: 'hotelDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name && data.model_name === 'hotel_room') {
        this.$router.push({
          name: 'roomDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleSwitchChange (data) {
      const { jsonItemData, rowData } = data
      this.$store.state.app.loading = this.$loading(loading)
      if (rowData[jsonItemData.prop].value) {
        updatePolicyOnline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      } else {
        updatePolicyOffline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getPolicyList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>
